<template>
  <v-container fluid>
    <TableViewComponent
      title="Table produits"
      @addItemEvent="onItem()"
      addItemLabel="ajouter un produit"
      itemLabel="produit"
      itemsLabel="produits"
      :items="entities"
      :columns="buildColumns()"
      deleteItemLabel="Voulez-vous supprimer ce produit ?"
      :pagination="false"
      :loading="loading"
      :vcols="{ xl: '6', lg: '8', md: '10', sm: '12', xs: '12' }"
      :rolesForEdition="rolesForEdition"
      :rolesForAddDelete="rolesForAddDelete"
      :rolesForRead="rolesForRead"
    ></TableViewComponent>

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import TableViewComponent from "@/components/ui/TableViewComponent.vue";

import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import { AvecProductsService } from "@/service/conf/avec_products_service.js";
import { ActivityService } from "@/service/sfr/activity_service.js";
import SubActivitiesService from "@/service/sfr/sub_activity_service.js";
import { MedicalSpecialtiesService } from "@/service/practitioners/medical_specialties_service.js";
import { AvecProductCategoryService } from "@/service/conf/avec_product_category_service.js"

import * as exceptions from "@/service/exception_to_message.js";

import { defines as routes } from "@/router/defines.js";

import { RolesApplicationEnum } from "@/service/roles/roles_application.js";

export default {
  name: "TableAvecProducts",
  components: { TableViewComponent },
  mixins: [SnackBarMixin],
  data() {
    return {
      /**en cours de chargement */
      loading: false,

      /** le service des entités */
      service: null,
      /** le service des activités */
      serviceActivities: null,
      /** le service des sous-activités */
      serviceSubActivities: null,
      /** le service des spécialités médicales */
      serviceMedicalSpecialities: null,
      /** le service des catégories de produit */
      serviceProductCategory: null,

      /**les éléments */
      entities: [],
    };
  },
  methods: {
    onItem() {
      this.$router.push(routes.avecProducts.add.path);
    },
    async load() {
      try {
        this.loading = true;

        this.entities = [];

        // Récupération de tous les produits
        let entities = await this.service.getAll();
        // Récupération de toutes les activités
        let activities = await this.serviceActivities.getActivities();
        // Récupération de toutes les sous-activités
        let subActivities = await this.serviceSubActivities.getAllSubActivities();
        // Récupération de toutes les spécialités médicales
        let medicalSpecialities = await this.serviceMedicalSpecialities.getAll();
        // Récupération de l'ensemble des catégiories
        let categories = await this.serviceProductCategory.getAll();

        // Tri des rows par ordre alphabétique
        entities.sort(function(a, b) {
          return a.digitalName.localeCompare(b.digitalName);
        });

        entities.forEach((e) => {
          let entity = JSON.parse(JSON.stringify(e));

          // Recherche de la catégorie
          entity.categoryName = "-";
          if (entity.category) {
            let foundCat = categories.find((c) => c.id == entity.category);

            entity.categoryName = foundCat.label;
          } 

          // Recherche de l'activité si présente
          entity.activityName = "-";
          if (e.activity) {            
            let foundAct = activities.find((a) => a.id == e.activity);

            entity.activityName = foundAct.name;
          }

          // Recherche de la sous-activité si présente
          entity.subActivityName = "-";
          if (e.subActivity) {
            let foundSub = subActivities.find((s) => s.id == e.subActivity);

            entity.subActivityName = foundSub.label;
          }

          // Recherche de la spécialité médicale si présente
          entity.medicalSpecialityName = "-";
          if (e.medicalSpeciality) {
            let foundMed = medicalSpecialities.find((m) => m.id == e.medicalSpeciality);

            entity.medicalSpecialityName = foundMed.name;
          }


          //action de consultation
          entity.view = () => {
            this.$router.push(
              routes.avecProducts.view.root + "/" + entity.id
            );
          };

          //action de modification
          entity.edit = () => {
            this.$router.push(
              routes.avecProducts.edit.root + "/" + entity.id
            );
          };

          //action de suppression
          entity.delete = async () => {
            try {
              //await this.service.delete(entity.id);
              await this.deleteProduct(entity.id);
              this.load();
            } catch (error) {
              console.error(error);
              this.addErrorToSnackbar(
                "suppression du produit : " +
                  (exceptions.toMessage(error) || "problème technique")
              );
            }
          };

          this.entities.push(entity);
        });
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },

    /** Gestions de la suppression d'un produit */
    async deleteProduct(productId) {

      let allSpecificDescription = await this.service.getAllSpecificDescription(productId);

      for (let specificDescription of allSpecificDescription) {

        await this.service.deleteSpecificDescription(specificDescription.establishmentid, productId);

      }

      await this.service.delete(productId);



    },

    /**Construire les colonnes à afficher dans la vue table */
    buildColumns() {
      let columns = [
        {
          text: "Catégorie",
          align: "start",
          sortable: true,
          value: "categoryName",
          default: true,
        },
        {
          text: "Nom digital",
          align: "start",
          sortable: true,
          value: "digitalName",
          default: true,
        },
        {
          text: "Nom commercial",
          align: "start",
          sortable: true,
          value: "commercialName",
          default: true,
        },
        {
          text: "Description",
          align: "start",
          sortable: true,
          value: "description",
          default: false,
        },
        {
          text: "Activité",
          align: "start",
          sortable: true,
          value: "activityName",
          default: true,
        },
        {
          text: "Sous-activité",
          align: "start",
          sortable: true,
          value: "subActivityName",
          default: true,
        },
        {
          text: "Spécialité médicale",
          align: "start",
          sortable: true,
          value: "medicalSpecialityName",
          default: false,
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center",
          width: "80px",
          default: true,
        },
      ];

      return columns;
    },
  },
  computed: {
    /**Retourne la liste des rôles attendus pour l'édition */
    rolesForEdition() {
      return [RolesApplicationEnum.EditAvecProduct];
    },
    /** Retourne la liste des rôles attendus pour l'ajout/suppression */
    rolesForAddDelete() {
      return [ RolesApplicationEnum.EditAvecProduct, ];
    },
    /**Retourne la liste des rôles attendus pour la lecture */
    rolesForRead() {
      return [RolesApplicationEnum.ReadAvecProduct];
    },
  },
  mounted() {
    
    this.service = new AvecProductsService(this.$api.getTransmissionsAvecProductsApi());
    this.serviceActivities = new ActivityService(this.$api);
    this.serviceSubActivities = new SubActivitiesService(this.$api);
    this.serviceMedicalSpecialities = new MedicalSpecialtiesService(
      this.$api.getMedicalSpecialitiesApi()
    );
    this.serviceProductCategory = new AvecProductCategoryService(this.$api.getProductCategory());

    this.load();
  },
};
</script>

<style>
</style>